const application = {
    invalid_type: '只有机器对机器应用程序可以有关联角色。',
    role_exists: '角色 ID {{roleId}} 已添加到此应用程序。',
    invalid_role_type: '无法将用户类型角色分配给机器对机器应用程序。',
    invalid_third_party_application_type: '只有传统网络应用程序可以标记为第三方应用。',
    third_party_application_only: '该功能仅适用于第三方应用程序。',
    user_consent_scopes_not_found: '无效的用户同意范围。',
    consent_management_api_scopes_not_allowed: '管理 API 范围不允许。',
    protected_app_metadata_is_required: '需要保护的应用程序元数据。',
    protected_app_not_configured: '受保护的应用程序提供程序未配置。 此功能不适用于开源版本。',
    cloudflare_unknown_error: '请求 Cloudflare API 时发生未知错误',
    protected_application_only: '该功能仅适用于受保护的应用程序。',
    protected_application_misconfigured: '受保护的应用程序配置不正确。',
    protected_application_subdomain_exists: '受保护的应用程序子域名已在使用中。',
    invalid_subdomain: '无效的子域名。',
    custom_domain_not_found: '未找到自定义域。',
    should_delete_custom_domains_first: '应先删除自定义域。',
    no_legacy_secret_found: '该应用程序没有旧版密钥。',
    secret_name_exists: '密钥名称已存在。',
    saml: {
        use_saml_app_api: '使用 `[METHOD] /saml-applications(/.*)?` API 操作 SAML 应用。',
        saml_application_only: '该 API 仅适用于 SAML 应用。',
        reach_oss_limit: '你不能创建更多 SAML 应用，因为已达到 {{limit}} 的限制。',
        acs_url_binding_not_supported: '仅支持用于接收 SAML 断言的 HTTP-POST 绑定。',
        can_not_delete_active_secret: '不能删除活动密钥。',
        no_active_secret: '未找到活动密钥。',
        entity_id_required: '生成元数据需要实体 ID。',
        name_id_format_required: '需要名称 ID 格式。',
        unsupported_name_id_format: '不支持的名称 ID 格式。',
        missing_email_address: '用户没有电子邮件地址。',
        email_address_unverified: '用户电子邮件地址未验证。',
        invalid_certificate_pem_format: '无效的 PEM 证书格式',
        acs_url_required: '需要断言消费服务 URL。',
        private_key_required: '需要私钥。',
        certificate_required: '需要证书。',
        invalid_saml_request: '无效的 SAML 认证请求。',
        auth_request_issuer_not_match: 'SAML 认证请求的发行者与服务提供者实体 ID 不匹配。',
        sp_initiated_saml_sso_session_not_found_in_cookies: '在 cookies 中未找到服务提供者发起的 SAML 单点登录会话 ID。',
        sp_initiated_saml_sso_session_not_found: '未找到服务提供者发起的 SAML 单点登录会话。',
        state_mismatch: '`state` 不匹配。',
    },
};
export default Object.freeze(application);
